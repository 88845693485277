import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>El Llibre</Title>
                <Text>
                    Amb aquest llibre tens el complement perfecte del primer volum “Método A. Las
                    500 preguntas más repetidas en los exámenes de Policía Local”.
                    <br />
                    <br />
                    500 noves preguntes de cultura general d'exàmens de municipis de tota Catalunya,
                    amb el qual ampliaràs els teus coneixements i assoliràs el nivell cultural
                    necessari per aconseguir la teva plaça.
                    <br />
                    <br />
                    Cinc-centes preguntes d'exàmens oficials.
                    <br />
                    <br />
                    Una única resposta a cada pregunta.
                    <br />
                    <br />
                    Ampliació d'informació a les preguntes més rellevants.
                    <br />
                    <br />
                    Accés durant un any al mòdul específic del llibre a l'app iGuB.
                </Text>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>. Recuerda
                        que puedes combinar diferentes productos en una misma compra.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
